import React, { Component } from "react"
import styled from "styled-components"
import NewsletterSignUpForm from "./NewsletterSignUpForm"
import { styles } from "../../utils"
import { FaFacebook, FaInstagram } from "react-icons/fa"

export default class Newsletter extends Component {
  render() {
    return (
      <NewsletterWrapper>
        <article className="newsletter" align="center">
          <header>
            <h3>Keep in touch...</h3>
            <p>Join Our Newsletter!</p>
          </header>
          <div className="form-wrapper">
            <NewsletterSignUpForm></NewsletterSignUpForm>
          </div>
          <footer>
            <div className="title">Find Us Online:</div>
            <a href="https://www.facebook.com/AzaleaBarKitchen/">
              <FaFacebook className="icon facebook-icon" />
              <span>Facebook</span>
            </a>
            <a href="https://www.instagram.com/azaleabarkitchen/">
              <FaInstagram className="icon instagram-icon" />
              <span>Instagram</span>
            </a>
          </footer>
        </article>
      </NewsletterWrapper>
    )
  }
}
const NewsletterWrapper = styled.section`
  --s1: 5%;
  --s2: calc(100% - 5%);
  background: linear-gradient(
    45deg,
    ${styles.colors.mainWhite} var(--s1),
    ${styles.colors.secondaryColor} var(--s1),
    ${styles.colors.secondaryColor} var(--s2),
    ${styles.colors.mainWhite} var(--s2)
  );
  color: ${styles.colors.mainBlack};
  padding: 6rem 0;

  .newsletter {
    margin: auto;
    max-width: 90vw;
    width: 50ch;
  }

  .newsletter header {
    text-align: left;
  }

  .newsletter header h3 {
    font: ${styles.fontDecor({ size: `3rem`, weight: 600 })};
  }

  .newsletter header p {
    font: ${styles.fontBody({ size: `1.5rem` })};
    text-transform: uppercase;
  }

  .newsletter #newsletter_form form {
    padding: 0 !important;
  }

  .newsletter #newsletter_form ._form_element:not(:first-child),
  .newsletter #newsletter_form ._button-wrapper {
    margin-top: 1.25rem;
  }

  .newsletter #newsletter_form ._form_element {
    isolation: isolate;
  }

  .newsletter #newsletter_form label {
    background: ${styles.colors.secondaryColor};
    color: ${styles.colors.mainBlack};
    font: ${styles.fontBody({ size: `1rem`, weight: 700 })};
    padding: 0 0.5rem 0.5rem 0;
    text-transform: uppercase;
    translate: 0 1rem;
    width: fit-content;
    z-index: 10;
  }

  .newsletter #newsletter_form ._field-wrapper {
    z-index: -10;
  }

  .newsletter #newsletter_form ._field-wrapper input {
    background: ${styles.colors.secondaryColor};
    border: 2px solid ${styles.colors.mainBlack};
    color: ${styles.colors.mainBlack};
    font: ${styles.fontBody({ size: `1.25rem`, weight: 700 })};
    padding: 0.75rem;
    text-transform: uppercase;

    &::placeholder {
      color: ${styles.colors.secondaryAlt({ l: `45%` })};
      font-size: 1rem;
    }
  }

  .newsletter #newsletter_form ._button-wrapper {
    text-align: right;
  }

  .newsletter #newsletter_form ._button-wrapper button {
    --x: 80%;
    --y: 20%;
    --y: 0%;

    box-shadow: none;
    background: transparent !important;
    color: ${styles.colors.secondaryColor} !important;
    font: ${styles.fontBody({ size: `1.25rem`, weight: 700 })};
    isolation: isolate;
    letter-spacing: 2px;
    padding: 1.25rem 2rem 1.25rem 1rem !important;
    position: relative;
    text-shadow: none;
    width: fit-content;

    &:hover {
      color: white !important;
      --x: 70%;
      --y: 17%;
    }

    &::after {
      background: ${styles.colors.mainBlack};
      clip-path: polygon(
        0% var(--y),
        var(--x) var(--y),
        var(--x) 0%,
        100% 50%,
        var(--x) 100%,
        var(--x) calc(100% - var(--y)),
        0% calc(100% - var(--y))
      );
      content: "";
      height: 100%;
      inset: 0;
      position: absolute;
      transition: 200ms ease !important;
      width: 100%;
      z-index: -10;
    }
  }

  .newsletter footer {
    align-items: center;
    display: grid;
    grid-template: auto auto / 1fr 1fr;
    margin-top: 4rem;
    padding: 1rem 0;
    row-gap: 1.5rem;
    width: 100%;

    .title {
      font-size: 1.2rem;
      grid-column: 1/3;
      line-height: 1;
      text-align: left;
      text-transform: uppercase;
      width: 100%;
      word-wrap: balance;
    }

    a {
      align-items: center;
      color: ${styles.colors.mainBlack};
      display: flex;
      font-size: 1.25rem;
      font-weight: 700;
      gap: 0.5rem;

      svg {
        font-size: 2rem;
      }

      span {
        border-bottom: 2px dotted var(--border-b-clr, transparent);
      }

      &:hover span {
        --border-b-clr: ${styles.colors.mainBlack};
      }

      &:last-child {
        place-self: end;
      }
    }
  }

  @media (max-width: 768px) {
    .newsletter h4 {
      font-size: 2.3rem;
    }
  }
`
