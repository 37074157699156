import React from "react"
import styled from "styled-components"
import { styles } from "../../../utils"
import { FaInstagram, FaFacebook } from "react-icons/fa"
import navConfig from "./config"

const NavbarIcons = () => {
  const icons = [
    {
      icon: <FaFacebook className="icon facebook-icon" />,
      path: "https://www.facebook.com/AzaleaBarKitchen/",
    },
    {
      icon: <FaInstagram className="icon instagram-icon" />,
      path: "https://www.instagram.com/azaleabarkitchen/",
    },
  ]

  return (
    <IconWrapper config={navConfig}>
      {icons.map(({ icon, path }, index) => {
        return (
          <a href={path} key={index} target="_blank" rel="noopener noreferrer">
            {icon}
          </a>
        )
      })}
    </IconWrapper>
  )
}

const IconWrapper = styled.div`
  .icon {
    font-size: 1.3rem;
    color: ${styles.colors.mainWhite};
    ${styles.transObject({})};
  }

  .icon:hover {
    color: ${styles.colors.secondaryColor};
  }

  display: none;

  @media (min-width: ${({ config }) => config.breakpoint}) {
    display: flex;
    gap: 2rem;
    width: fit-content;
    justify-content: end;
  }
`

export default NavbarIcons
