import styled from "styled-components"
import React from "react"

// function based component
// pass down props to this component ({img and children})
function HomeHeader({ children, ...props }) {
  return <IndexHeader {...props}>{children}</IndexHeader>
}

function PageHeader({ children, ...props }) {
  return <IndexHeader {...props}>{children}</IndexHeader>
}

function AboutHeader({ children, ...props }) {
  const _props = {
    from: `oklch(0% 0 0 / 0.3)`,
    to: `oklch(0% 0 0 / 0.7)`,
    ...props,
  }
  return <BlendHeader {..._props}>{children}</BlendHeader>
}

function PatioHeader({ children, ...props }) {
  const _props = {
    alignItems: `end`,
    ...props,
  }
  return <LinearBlendHeader {..._props}>{children}</LinearBlendHeader>
}

function CateringHeader({ children, ...props }) {
  return <OpaqueHeader {...props}>{children}</OpaqueHeader>
}

const IndexHeader = styled.section`
  --shape: 120% 60%;
  --from: oklch(0% 0 0 / 0.15);
  --to: oklch(0% 0 0 / 1);
  --img-url: url(${props => props.smImg || props.lgImg || props.img});

  min-height: 100vh;
  position: relative;

  background-image: var(--img-url),
    radial-gradient(var(--shape), var(--from), var(--to));
  background-blend-mode: darken;

  background-repeat: no-repeat;
  background-position: ${props => props.bgPosSm || props.bgPos || `center`};
  background-attachment: ${props => props.bgAttachment || `local`};
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: ${props => props.alignItems || `center`};

  @media (min-width: 576px) {
    --shape: 140% 67%;
    --img-url: url(${props => props.img || props.smImg});
    background-position: ${props => props.bgPos || `center`};
  }

  @media (min-width: 992px) {
    --shape: 140% 67%;
    --img-url: url(${props => props.lgImg || props.img || props.smImg});
    background-position: ${props => props.bgPosLg || props.bgPos || `center`};
  }
`

const BlendHeader = styled(IndexHeader)`
  background-image: var(--img-url),
    radial-gradient(
      200% 50%,
      ${props => props.from || `oklch(0% 0 0 / 0.6)`},
      ${props => props.to || `oklch(0% 0 0 / 0.9)`}
    );
`

const LinearBlendHeader = styled(IndexHeader)`
  background-image: var(--img-url),
    linear-gradient(
      ${props => props.from || `oklch(0% 0 0 / 0)`},
      ${props => props.to || `oklch(0% 0 0 / 0.65)`}
    );
`

const TanHeader = styled(IndexHeader)`
  background-color: #947e61;

  h1 {
    padding: 3rem 0 3rem 0;
  }
`
const OpaqueHeader = styled(IndexHeader)`
  background-image: url(${props => props.img}),
    linear-gradient(180deg, #929ba2, #ddbf84);
  background-blend-mode: darken;

  h1 {
    padding: 3rem 0 3rem 0;
  }
`

// fallback default images
HomeHeader.defaultProps = {
  img: null,
}
PageHeader.defaultProps = {
  img: null,
}

export {
  HomeHeader,
  PageHeader,
  AboutHeader,
  PatioHeader,
  TanHeader,
  CateringHeader,
}
