import styled from "styled-components"
import { styles } from "../utils"

// not a class based or a functional component
const BannerButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 0.25em;
  margin-top: 3rem;
  ${styles.transition({})};
  background-color: ${styles.colors.secondaryColor};
  border-color: ${styles.colors.mainYellow};
  font-family: "aw-conqueror-didot";
  font-size: 1.5em;
  line-height: 1.5em;
  color: #fff;
  &:hover {
    background-color: ${styles.colors.mainYellow} !important;
    transition-duration: 0.25s;
    cursor: pointer;
  }
  a {
    color: #fff;
  }
  a:hover {
    color: #fff;
    text-decoration: none;
  }
`

const SectionButton = styled(BannerButton)`
  color: ${styles.colors.mainBlack};
  ${styles.border({ color: `${styles.colors.mainBlack}` })};
  &:hover {
    background: ${styles.colors.mainBlack};
    color: ${styles.colors.darkYellow};
    cursor: pointer;
  }
`

export { BannerButton, SectionButton }
