// class based component just in case we ever need to add state to it
import React, { Component } from "react"
import { Link } from "gatsby"
import logo from "../../../images/azalea-logo-h.svg"
import { styles } from "../../../utils"
import { FaBars, FaTimes } from "react-icons/fa"
import styled from "styled-components"
import navConfig from "./config"

const MenuIcon = ({ navbarOpen }) => {
  const props = { className: `toggle-icon` }
  if (navbarOpen) {
    return <FaTimes {...props} />
  }
  return <FaBars {...props} />
}

// we passed down props to this component
export default class NavbarHeader extends Component {
  render() {
    const { handleNavbar, navbarOpen } = this.props
    return (
      <HeaderWrapper config={navConfig} open={navbarOpen}>
        <Link to="/">
          <img src={logo} alt="azalea bar & kitchen" className="nav-logo" />
        </Link>
        <MenuButton onClick={() => handleNavbar()}>
          <MenuIcon navbarOpen={navbarOpen} />
        </MenuButton>
      </HeaderWrapper>
    )
  }
}

// We have access to variables inside
// template literals. It's just JS derp!
const HeaderWrapper = styled.div`
  --px: 1rem;
  padding: 0.4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .toggle-icon {
    font-size: 1.75rem;
    color: ${styles.colors.mainWhite};
  }
  .nav-logo {
    width: 10rem;
    height: auto;
    z-index: 30;
  }

  @media (min-width: 576px) {
    --px: 2rem;
  }

  @media (min-width: ${({ config }) => config.breakpoint}) {
    .toggle-icon {
      display: none;
      --px: 0;
    }
  }
`

const MenuButton = styled.button`
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-radius: 0.25rem;
  height: fit-content;
  width: fit-content;
  padding: 0;
  cursor: pointer;
  z-index: 20;
`
