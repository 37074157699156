import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { styles } from "../../../utils"
import navConfig from "./config"

const NavbarLinks = ({ navbarOpen }) => (
  <LinkWrapper config={navConfig} open={navbarOpen}>
    {navConfig.links.map(({ name, path }, i) => (
      <li key={i}>
        <Link to={path} className="nav-link">
          {name}
        </Link>
      </li>
    ))}
  </LinkWrapper>
)

// styled components can accept props too!
// when passing props to styled components
// must pass it as a function (see height)
const LinkWrapper = styled.menu`
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: hidden;
  display: ${props => (props.open ? `flex` : `none`)};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
  position: fixed;
  inset: 0;
  font-size: 3rem;
  z-index: 10;

  li {
    list-style-type: none;
    text-align: center;
    width: fit-content;
  }

  .nav-link {
    --scale: 1 0;
    font-weight: bold;
    text-decoration: none;
    color: ${styles.colors.mainWhite};
    text-transform: uppercase;
    letter-spacing: 4px;
    position: relative;
    overflow: hidden;
    padding: 0;
    isolation: isolate;

    &::after {
      background: ${styles.colors.mainYellow};
      bottom: 0;
      content: "";
      height: 0.25rem;
      left: 0;
      position: absolute;
      scale: var(--scale);
      transform-origin: bottom;
      translate: -2px 0;
      width: 100%;
      z-index: -1;
      ${styles.transDefault};
    }
    ${styles.transDefault};

    &:hover,
    &[aria-current="page"] {
      --scale: 1 1;
      color: ${styles.colors.mainYellow};
    }
  }

  @media (min-width: ${({ config }) => config.breakpoint}) {
    background: transparent;
    display: flex;
    height: fit-content;
    width: fit-content;
    flex-direction: row;
    justify-content: center;
    column-gap: 2rem;
    margin: auto;
    font-size: 1rem;
    position: static;
    width: 100%;
  }
`

export default NavbarLinks
